import { makeStyles } from "@material-ui/core";

export const useDialogWrapperStyles = makeStyles((theme) => ({
    dialogContent: {
        maxWidth: 550,
        minWidth: 360,
        minHeight: 600,
        padding: "30px"
    },
    logoIcon: {
        margin: "0 auto",
        width: 30,
        "& svg": {
            height: "1.75rem",
            width: "1.75rem",
            color: theme.palette.primary.main
        }
    },
    button: {
        maxWidth: 490,
        position: "static",
        bottom: 0,
        marginBottom: 15,
        padding: "16px",
        height: "unset"
    }
}));