import axios from "axios";
import { TOKEN } from "../constants/common-constants";

axios.interceptors.request.use((config) => {
    const token = localStorage.getItem(TOKEN);
    if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
});

export { axios };
