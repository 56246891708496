import { RootState } from "../../store";
import { UsersState } from "./contracts/state";
import { LoadingStatus } from "../../../types/common";
import { UserState } from "./contracts/state";

export const selectUsersState = (state: RootState): UsersState => state.users;
export const selectUsers = (state: RootState): UsersState["users"] => state.users.users;
export const selectPagesCount = (state: RootState): UsersState["pagesCount"] => selectUsersState(state).pagesCount;
export const selectUsersIsLoading = (state: RootState): boolean => selectUsersState(state).loadingState === LoadingStatus.LOADING;

//for get logged in user
export const selectUserState = (state: RootState): UserState => state.user;
export const selectUserData = (state: RootState): UserState["data"] => selectUserState(state).data;
export const selectUserDataId = (state: RootState) => selectUserData(state)?.id;

