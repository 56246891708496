import { makeStyles } from "@material-ui/core";

export const useBlockedAccountStyles = makeStyles((theme) => ({
    tabs: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        "& .MuiTabs-indicator": {
            marginLeft: 0,
            maxWidth: "unset",
            height: 4,
            backgroundColor: theme.palette.primary.main
        },
        "& .MuiTab-root": {
            fontWeight: 700
        }
    },
}));