export const wallpapers = [
    "https://pbs.twimg.com/media/EXZ2rMvVAAAAfrN?format=png&name=small",
    "https://pbs.twimg.com/media/EXZ22fjU8AAau2G?format=png&name=small",
    "https://pbs.twimg.com/media/EXZ2fFgVAAAnpYk?format=png&name=small",
    "https://pbs.twimg.com/media/EXZ27UwVcAIcDfd?format=png&name=small",
    "https://pbs.twimg.com/media/EXZ1_hkUYAA56JA?format=png&name=small",
    "https://pbs.twimg.com/media/EXZ2mJCUEAEbJb3?format=png&name=small",
    "https://pbs.twimg.com/media/EXZ3BXhUwAEFNBE?format=png&name=small",
    "https://pbs.twimg.com/media/EXZ2w_qUcAMwN3x?format=png&name=small"
];
