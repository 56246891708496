import { makeStyles } from "@material-ui/core";

export const useEmailVerificationModalStyles = makeStyles(() => ({
    title: {
        fontSize: 18,
        fontWeight: 800,
        marginBottom: 22,
        linHeight: "24px"
    },
    emailLinkWrapper: {
        display: "block",
        marginLeft: 6,
        marginTop: 6,
        marginBottom: 12
    }
}));