import { makeStyles } from "@material-ui/core";

export const useRegistrationModalStyles = makeStyles(() => ({
    title: {
        marginTop: 20,
        marginBottom: 28
    },
    inputWrapper: {
        display: "flex",
        justifyContent: "space-between"
    },
    phoneLink: {
        display: "inline-block",
        margin: "0px 0 32px 0"
    },
    footer: {
        marginBottom: 47,
        "& .MuiTypography-h6": {
            fontSize: "20px",
            fontWeight: 700,
            marginBottom: "4px"
        },
        "& .MuiTypography-subtitle1": {
            fontSize: "16px"
        }
    },
    formControl: {
        margin: "16px 0",
        display: "flex",
        "& .Mui-error": {
            marginInline: 0
        }
    }
}));
